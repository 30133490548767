@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mr-6 {
  margin-right: 30px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.ml-6 {
  margin-left: 30px !important;
}

.d-flex {
  display: flex;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pl-4 {
  padding-left: 20px !important;
}
.pl-5 {
  padding-left: 25px !important;
}
.pl-6 {
  margin-left: 30px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.align-center{
  align-items: center;
}
.rightSideBar{
  width: calc(100% - 88px);
  margin-left: 88px;
  min-height: 100%;
  overflow: auto;
}
.rightSideBar.bgWhite{
  background: #fff;
}
.w-100{width: 100%;}
.h-100{height: 100%;}
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.customInputField,
.customInputFieldSelect {
    margin-top: 25px !important;
    font-size: 12px !important;
    line-height: 20px;
    font-style: ubuntu !important;
    letter-spacing: 0px;
    color: #828282 !important;
    opacity: 1;
    border: solid 1px #828282;
    padding: 0px 8px;
    font-Weight: regular

}

.customInput input[type='text'] , 
.customInputFieldSelect input[type='text'] {padding: 0 ; height : 30px}
.customInputField::after,
.customInputField::before {
  display: none;
}

.customInputFieldSelect::before{
    font-family: "Material Icons";
    content: '\e313' !important;
    left: auto !important;
    right: 14px !important;
    bottom: auto !important;
    top: 7px;
    border-bottom: 0 !important;
    font-size: 22px;
}

#bcpFilter .optionsWrapper {
    height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

#bcpFilter .optionsWrapper button{
  text-align:left;
}
#bcpFilter [class*="scrollbar_scrollerFilter"] {
  max-height : 250px; height: 100%; 
}

.pmHandleCustomAuto .MuiAutocomplete-popper{
  margin-top: 25px;
}
.pmHandleCustomAuto .MuiAutocomplete-popper li{
  font-size: 14px;
  padding: 0;
}
.pmHandleCustomAuto .MuiAutocomplete-popper li svg{
  font-size: 16px;
}
