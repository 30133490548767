.survayViewTable .linkWrapper {
    align-items: center;
    justify-content: space-between;
}
.survayViewTable .linkWrapper a {
    color: #1381b9;
    display: flex;
    align-items: center;
}
.survayViewTable .linkWrapper img {
    height: 16px;
}
#customized-menu .MuiListItem-button, #customized-menu .viewLink {
    text-decoration: none;
    color: #2b0a3d;
}
#customized-menu .MuiListItem-button img, #customized-menu .viewLink img {
    height: 15px;
}
