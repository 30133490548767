.react-datepicker__day-name
{
 
    font: italic normal normal 10px/20px Ubuntu;
    letter-spacing: 0px;
    color: #0070AD;
    opacity: 1;
    margin:0.01rem;
}
.react-datepicker__day--selected
{
    border-radius: 0.3rem;
    background-color: hsl(206, 67%, 39%);
    color: #F8F9FA !important;
    opacity: 1;
}
.react-datepicker__day
 {
   
   
    font: normal normal normal 10px/20px Ubuntu;
    letter-spacing: 0px;
    color: #2B0A3D;
    opacity: 1;
    /*
    border-radius: 50%;
    line-height:1.6re;
    width: 1.2rem;
    */

    margin:0.01rem;
}
.react-datepicker__day--disabled
{
    font: normal normal normal 10px/20px Ubuntu;
    letter-spacing: 0px;
    color:  #828282;
    opacity: 1;
    margin:0.01rem;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #0070AD !important;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
 font: normal normal normal 10px/11px Ubuntu;
letter-spacing: 0px;
color: #828282;
opacity: 1;
}

.react-datepicker__header {
    text-align: center;
    padding: 8px 0;
    position: relative;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    border: 1px solid #EEEEEE;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
}