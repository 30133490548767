.container {
    overflow: auto;
}

.button-container {
    display: flex;
    justify-content: center;
    padding: 22px 10px 10px 10px;
    gap: 2px
}

.button-container :first-child {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px
}

.button-container :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px
}

.adminDashHeadingWrap {
    align-items: center;
    justify-content: flex-end;
    padding: 24px 2%;
    margin: 24px 24px 0px;
}

.adminDashHeadingWrap .MuiButtonGroup-root.roundedBtn>button:first-child {
    border-radius: 50px 0 0 50px;
    text-transform: none;
    box-shadow: none;
    background-color: #1381B9;
    margin-top: 30;
}

.adminDashHeadingWrap .MuiButtonGroup-root.roundedBtn>button:first-child+button {
    border-radius: 0 50px 50px 0;
    text-transform: none;
    box-shadow: none;
    margin-top: 30;
}

.styled-button,
.styled-button-inactive,
.styled-button-active {
    font-size: 16px;
    font-weight: 400;
    width: 143px;
    height: 38px;
    border: 1px solid #0070ad;
    cursor: pointer
}

.styled-button-active {
    background-color: #0070ad;
    color: #fff
}

.styled-button-inactive {
    background-color: #fff;
    color: #0070ad
}

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    margin-bottom: 20px
}

.card-content {
    min-width: 47%;
    min-height: 40%;
    border: 1.5px solid rgba(196, 196, 196, .6);
    padding: 20px;
    cursor: pointer
}

.card-content:hover {
    border: 1.5px solid #1381b9
}

.card-content a {
    text-decoration: none
}

.card-header {
    font: normal normal medium 16px/18px !important;
    font-weight: 500;
    letter-spacing: 0px;
    color: #2B0A3D;
    opacity: 1;
}

.card-description {
    color: #2b0a3d;
    max-width: 350px;
}

.card-icon {
    width: 100px
}

.card-body {
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 30px 0 30px 40px
}

@media only screen and (max-width: 1920px)and (min-width: 1654px) {
    .card-icon {
        width: 140px
    }

    .card-body {
        gap: 7em
    }
}

@media only screen and (max-width: 1024px) {
    .card-icon {
        width: 90px
    }

    .card-body {
        gap: 4em
    }

    .card-content {
        min-width: 100%;
        min-height: 40%
    }

    .card-container {
        margin-left: 50px;
        margin-right: 50px
    }
}